import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { EMPTY, Observable, Subject, from, of, timer } from 'rxjs';
import { catchError, delay, switchMap, tap } from 'rxjs/operators';
import { MatSnackBar, MatSnackBarRef, TextOnlySnackBar } from '@angular/material/snack-bar';
import { SwPush, SwUpdate, VersionEvent } from '@angular/service-worker';
import { environment } from '../../../environments/environment';
import { CustomHeader } from '../models/header.enum';

@Injectable({
    providedIn: 'root',
})

export class NotificationService {
    public busy$ = new Subject<boolean>();
    private readonly http = inject(HttpClient);
    private readonly snack = inject(MatSnackBar);
    private readonly swUpdate = inject(SwUpdate);
    private readonly swPush = inject(SwPush);


    getAll(): Observable<Notification[]> {
        return this.http.get<Notification[]>(`${environment.urls.api}notifications`);
    }


    getCount(userId: number): Observable<number> {
        return timer(0, 30e3)
            .pipe(
                delay(30e3),
                switchMap(() => {
                    return this.http.get<number>(
                        `${environment.urls.api}notification/count/${userId}`,
                        { headers: { [CustomHeader.SkipLoadingHeader]: '' } }
                    ).pipe(
                        tap(t => this.setBadge(t)),
                        catchError(() => EMPTY),
                    );
                })
            );
    }


    close(notificationId: number): Observable<void> {
        return this.http.put<void>(`${environment.urls.api}notification`, notificationId);
    }


    infoMsg(message: string, duration = 5000, action = '', panelClass = ['snack-success']): MatSnackBarRef<TextOnlySnackBar> {
        return this.snack.open(message, action, { duration, panelClass: panelClass });
    }


    warnMsg(message: string, duration = 5000, action = '', panelClass = ['snack-warn']): MatSnackBarRef<TextOnlySnackBar> {
        return this.snack.open(message, action, { duration, panelClass: panelClass });
    }


    errorMsg(message: string, duration = 5000, action = '', panelClass = ['snack-error']): MatSnackBarRef<TextOnlySnackBar> {
        this.vibrate();
        return this.snack.open(message, action, { duration, panelClass: panelClass });
    }


    busy(): void {
        setTimeout(() => {
            this.busy$.next(true);
        });
    }


    ready(): void {
        setTimeout(() => {
            this.busy$.next(false);
        });
    }


    checkForUpdates(): Observable<VersionEvent> {
        return this.swUpdate.isEnabled ?
            this.swUpdate.versionUpdates.pipe(
                tap(sw => {

                    switch (sw.type) {
                        case 'VERSION_DETECTED':
                            console.info(`Downloading new app version: ${sw.version.hash}`);
                            this.warnMsg(`Pobieranie nowej wersji...`);
                            break;

                        case 'VERSION_READY':
                            console.info(`New app version ready for use: ${sw.latestVersion.hash}`);
                            this.infoMsg(`Zainstalowano nową wersję`).afterDismissed().subscribe(
                                () => {
                                    const showWhatsNew = sw.latestVersion.appData
                                        && 'showWhatsNew' in sw.latestVersion?.appData
                                        && sw.latestVersion.appData.showWhatsNew;
                                    if (showWhatsNew) {
                                        window.location.href = '/help/1'; // reload to activate changes and show "What's new..."" page
                                    } else {
                                        window.location.reload(); // just trload page to activate changes
                                    }
                                }
                            );
                            break;

                        case 'VERSION_INSTALLATION_FAILED':
                            console.error(`Failed to install app version: ${sw.error}`);
                            this.errorMsg(`Nie udało się zainstalować nowej wersji aplikacji`);
                            break;
                    }
                })
            ) : EMPTY;
    }


    vibrate(time = 100): void {
        if ('vibrate' in navigator && window.navigator.userActivation.isActive) {
            navigator.vibrate(time);
        }
    }


    setBadge(badge: number): void {
        if ("setAppBadge" in navigator) {
            window.navigator.setAppBadge(badge);
        }
    }


    getNotificationsState(): Observable<PermissionState> {
        return ("permissions" in navigator) ?
            from(
                navigator.permissions.query({ name: "notifications" })
                    .then(result => result.state)
            )
            : of('denied')
    }


    requestNotifications(): Observable<void> {
        return this.getNotificationsState().pipe(
            delay(30e3),
            switchMap(state => {
                return this.swPush.isEnabled && state !== 'denied' ?
                    from(this.swPush.requestSubscription({
                        serverPublicKey: environment.push.publicKey
                    })).pipe(
                        switchMap(sub => this.http.post<void>(`${environment.urls.api}push`, sub.toJSON(), { headers: { [CustomHeader.SkipNotificationHeader]: '' } })
                        ))
                    : EMPTY
            })
        );
    }
}
